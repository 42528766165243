import React from 'react'
import { rhythm } from '../lib/typography'
import { useTheme } from 'components/Theming'
import Link from 'components/Link'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Description = styled.p`
  margin-bottom: 10px;
  display: inline-block;
`

const PostList = ({ notes }) => {
  const theme = useTheme()

  return (
    <>
      {notes.map(({ node: post }) => (
        <div
          key={post.id}
          css={css`
            margin-bottom: 40px;
          `}
        >
          <h2
            css={css({
              marginBottom: rhythm(0.3),
              transition: 'all 150ms ease',
              ':hover': {
                color: theme.colors.primary,
              },
            })}
          >
            <Link
              to={post.frontmatter.slug}
              aria-label={`View ${post.frontmatter.title}`}
            >
              {post.frontmatter.title}
            </Link>
          </h2>
          <Description>
            {post.excerpt}{' '}
            <Link
              to={post.frontmatter.slug}
              aria-label={`View ${post.frontmatter.title}`}
              css={css(`
                display: block;
                font-size: 0.75rem !important;
                letter-spacing: 0.1em;
                margin-top: 0.5rem;
                text-decoration: none;
                text-transform: uppercase;
                color: ${({theme}) => theme.colors.green} !important;
              `)}
            >
              Read this post  &rsaquo;
            </Link>
          </Description>
        </div>
      ))}
    </>
  )
}

export default PostList
