import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi there 👋`}</p>
    <p>{`My name is `}<a parentName="p" {...{
        "href": "https://sebastianauner.com/"
      }}>{`Sebastian`}</a>{` and I am a German software developer, but my fields of interest go far beyond development and technology. I also like to learn about self-improvement, productivity,  decision-making and much more.`}</p>
    <p>{`This is my digital garden. A place where I grow my ideas and learnings, and share them with you. If you have never heard of a digital garden, don't worry.`}</p>
    <p>{`Imagine a usual blog, but completely different.`}</p>
    <p>{`A blog usually has polished, well written articles that are meant for an audience and have the goal to attract as many readers as possible.
The digital garden follows a different approach. It focuses less on the presentation of a finished idea and instead emphasizes on the process of developing it.`}</p>
    <p>{`The goal is to work in public, `}<a parentName="p" {...{
        "href": "https://notes.andymatuschak.org/z21cgR9K3UcQ5a7yPsj2RUim3oM2TzdBByZu"
      }}>{`with the garage door open`}</a>{` and therefore inspire and spark new ideas. The output does not need to be perfect. The posts aren't even meant for an audience. They are primarily for me personally, meaning they give me the opportunity to make my mind about a topic and make my notes accessible from everywhere. This means, everything you read here is not addressed to you, but it is addressed to me. Don't get me wrong. Of course, you are free to read through my notes and draw inspiration, but don't be confused if anything doesn't make sense or seems like a very rough idea. After all, they are still my notes. 😉`}</p>
    <p>{`A garden generally is a place to grow plants. In a digital garden, the ideas presented in it are supposed to grow as well.`}</p>
    <p>{`Imagine each idea as a plant. It starts as a small seed 🌱. The idea is rough, the note is far from done. A lot of them simply stay that way if not tended regularly. But other notes get more attention and are visited regularly. They grow into buddings. The argument gets clearer, and a vision is visible. Finally, some posts grow into big evergreens 🌳, that make this garden shine.`}</p>
    <p>{`So if you find posts that seems a bit rough and don't make any sense, it's probably a seedling and still needs some time to grow. The ability to put out a note without fearing that it needs to be perfect allows me to just focus on writing and learning, and less on worrying about what others think about my text.`}</p>
    <p>{`I hope you enjoy being part of my learning process, feel free to `}<a parentName="p" {...{
        "href": "mailto:notes@sebastianauner.com"
      }}>{`get in touch`}</a>{`!`}</p>
    <p>{`Enjoy your stay!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      